// app/javascript/controllers/mobile_dropdown_search_controller.js
import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["query"];

  connect() {
    this.perform = debounce(this.perform, 500).bind(this);
  }

  perform() {
    const queryValue = this.queryTarget.value;
    if (queryValue.length > 1) {
      this.hideNavigationItems();
      this.element.requestSubmit();
    } else {
      this.hideMobileDropdownSearchResult();
      this.showNavigationItems();
    }
  }

  hideNavigationItems() {
    const navigationItems = document.querySelector("#navigation-items");
    if (navigationItems) {
      navigationItems.classList.add("hide");
    }
  }

  hideMobileDropdownSearchResult() {
    const mobileDropdownSearchResultElement = document.querySelector(
      "#mobile-dropdown-navbar-search-result"
    );
    if (mobileDropdownSearchResultElement) {
      mobileDropdownSearchResultElement.classList.add("hide");
    }
  }

  showNavigationItems() {
    const navigationItems = document.querySelector("#navigation-items");
    if (navigationItems) {
      navigationItems.classList.remove("hide");
    }
  }
}
