// app/javascript/controllers/search_controller.js
import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["query"];

  connect() {
    this.perform = debounce(this.perform, 500).bind(this);
    // sendo usado em conjunto com pages#define_item_exchangeable
    document.addEventListener("itemAppended", this.hideSearchResult.bind(this));
  }

  disconnect() {
    document.removeEventListener(
      "itemAppended",
      this.hideSearchResult.bind(this)
    );
  }

  perform() {
    const queryValue = this.queryTarget.value;
    if (queryValue.length > 1) {
      this.hideStandardDom();
      this.hideFooter();
      this.element.requestSubmit();
    } else {
      this.hideSearchResult();
      this.showStandardDom();
      this.showFooter();
    }
  }

  /* close icon em search result na versão DESKTOP */
  toggleSearchResultAndStandardDom() {
    this.hideSearchResult();
    this.showStandardDom();
    this.showFooter();
  }

  // ############################################################

  hideFooter() {
    const footerElement = document.querySelector(".footer-container");
    if (footerElement) {
      footerElement.classList.add("hide");
    }
  }

  hideStandardDom() {
    const standardDom = document.querySelector("#standard-dom");
    if (standardDom) {
      standardDom.classList.add("hide");
    }
  }

  hideSearchResult() {
    // 16/4/24 tentar manter apenas esse!
    const searchResultElement = document.querySelector("#search-result");
    if (searchResultElement) {
      searchResultElement.classList.add("hide");
    }

    // utilizado para esconder o resultado globalmente
    const navbarSearchResultElement = document.querySelector(
      "#navbar-search-result"
    );
    if (navbarSearchResultElement) {
      navbarSearchResultElement.classList.add("hide");
    }

    // utilizado para esconder o resultado de Sneaker Search em Dashboard
    const sneakerSearchElement = document.querySelector(
      "#sneaker-search-result"
    );
    if (sneakerSearchElement) {
      sneakerSearchElement.classList.add("hide");
    }
    // verificar os códigos da linha de baixo

    // const fetchedAdvancedResultElement = document.querySelector(
    //   "#fetched-advanced-search"
    // );

    // if (fetchedAdvancedResultElement) {
    //   fetchedAdvancedResultElement.classList.add("hide");
    // }
  }

  showFooter() {
    const footerElement = document.querySelector(".footer-container");
    if (footerElement) {
      footerElement.classList.remove("hide");
    }
  }

  showStandardDom() {
    const standardDom = document.querySelector("#standard-dom");
    if (standardDom) {
      standardDom.classList.remove("hide");
    }
  }
}
