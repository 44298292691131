// app/javascript/controllers/filter_menu_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "buttonsContainer",
    "selectedFilterContainer",
    "sneakerGradeFilter",
  ];

  slideFilterMenu() {
    const slidableFilterMenuElement = document.querySelector(
      "#slidableFilterMenu"
    );
    if (slidableFilterMenuElement) {
      slidableFilterMenuElement.classList.add("open");
    }
  }

  slideBackFilterMenu() {
    const slidableFilterMenuElement = document.querySelector(
      "#slidableFilterMenu"
    );
    if (slidableFilterMenuElement) {
      slidableFilterMenuElement.classList.remove("open");
    }
  }

  connect() {
    // Listen for clicks on the buttons container
    // this.buttonsContainerTarget.addEventListener("click", (event) => {
    //   this._handlePillButtonClick(event);
    // });
  }

  openMobileFilter() {
    this.hideNavbar();
    this.hideStandardDom();
  }

  hideNavbar() {
    const navbarElement = document.querySelector(".navbar");
    if (navbarElement) {
      navbarElement.classList.add("hide");
    }
  }

  hideStandardDom() {
    const standardDom = document.querySelector("#standard-dom");
    if (standardDom) {
      standardDom.classList.add("hide");
    }
  }

  active(event) {
    const spanElement = event.currentTarget;

    const textContent = spanElement.textContent.trim();

    // ******************************************************
    // Find the closest parent `.data-filter-menu` element
    // const filterMenuBlock = event.currentTarget.closest(".data-filter-menu");
    // ******************************************************

    // Check if the span is already active
    if (spanElement.classList.contains("active")) {
      // Remove the 'active' class
      spanElement.classList.remove("active");

      // Reset the innerHTML to just the original text, removing the checkbox image
      spanElement.innerHTML = textContent;

      // Find and remove the corresponding pill button
      const pillButton = Array.from(this.buttonsContainerTarget.children).find(
        (button) =>
          button.getAttribute("data-filter-text").trim() === textContent
      );

      // If 'Usado' is being deactivated, add the 'hide' class to the sneakerGradeFilter
      if (textContent === "Usado") {
        this.sneakerGradeFilterTarget.classList.add("hide");
      }

      if (pillButton) {
        this.buttonsContainerTarget.removeChild(pillButton);
      }

      // Hide the container if no more buttons are present
      if (this.buttonsContainerTarget.children.length === 0) {
        this.addHideClassToSelectedFilterContainer();
      }

      return; // Stop further execution
    } else {
      // If 'Usado' is being activated, remove the 'hide' class from the sneakerGradeFilter
      if (textContent === "Usado") {
        this.sneakerGradeFilterTarget.classList.remove("hide");
      }
    }

    // ******************************************************
    // // Manually construct an event-like object for the toggle button
    // const toggleButtonEvent = {
    //   currentTarget: filterMenuBlock.querySelector(".toggle-button"),
    // };

    // // Toggle the dropdown visibility
    // this.toggleMenuVisibility(toggleButtonEvent);
    // ******************************************************

    // Add 'active' class to the clicked span
    spanElement.classList.add("active");

    // Find the '.filter-menu-wrapper' element
    const filterMenuWrapper = spanElement.closest(".filter-menu-wrapper");

    // Check if the '.filter-menu-wrapper' has 'filter-by-size' class
    if (!filterMenuWrapper.classList.contains("filter-by-size")) {
      // Sanitize the text and add the icon
      const safeText = this.sanitizeText(textContent);
      spanElement.innerHTML = `${safeText} <img src="https://res.cloudinary.com/dannieltung/image/upload/v1708816304/icon_checkbox_etsszi.svg" alt="icon checkbox">`;
    } else {
      // Only add sanitized text if it's under 'filter-by-size'
      spanElement.textContent = this.sanitizeText(textContent);
    }

    this.removeHideClassFromSelectedFilterContainer();
    // Logic to add a button
    this._addPillButton(textContent);
  }

  closeFilterMenu() {
    this.hideFilterMenu();
    this.showNavbar();
    this.showStandardDom();
    this.slideBackFilterMenu();
  }

  hideFilterMenu() {
    const mobileFilterElement = document.querySelector("#filter-menu");
    if (mobileFilterElement) {
      mobileFilterElement.classList.add("hide");
    }
  }

  showNavbar() {
    const navbarElement = document.querySelector(".navbar");
    if (navbarElement) {
      navbarElement.classList.remove("hide");
    }
  }

  showStandardDom() {
    const standardDom = document.querySelector("#standard-dom");
    if (standardDom) {
      standardDom.classList.remove("hide");
    }
  }

  toggleMenuVisibility(event) {
    // Find the closest parent `.data-filter-menu` element
    const filterMenuBlock = event.currentTarget.closest(".data-filter-menu");

    // Find the `.filter-menu-wrapper` within the same block
    const filterMenu = filterMenuBlock.querySelector(".filter-menu-wrapper");

    // Toggle the 'hide' class on the found menu
    filterMenu.classList.toggle("hide");
  }

  // remove hide class from 'selected filter container'
  removeHideClassFromSelectedFilterContainer() {
    this.selectedFilterContainerTarget.classList.remove("hide");
  }

  // addd hide class to 'selected filter container'
  addHideClassToSelectedFilterContainer() {
    this.selectedFilterContainerTarget.classList.add("hide");
  }

  clearFilters() {
    // Remove all buttons from the buttons container
    while (this.buttonsContainerTarget.firstChild) {
      this.buttonsContainerTarget.removeChild(
        this.buttonsContainerTarget.firstChild
      );
    }

    // Remove 'active' class from all spans
    this._removeActiveFromClass();

    // Add the 'hide' class to the sneakerGradeFilter
    this.sneakerGradeFilterTarget.classList.add("hide");

    // Add 'hide' class to the 'selected filter container'
    this.addHideClassToSelectedFilterContainer();
  }

  sanitizeText(text) {
    const tempDiv = document.createElement("div");
    tempDiv.textContent = text;
    return tempDiv.innerHTML;
  }

  _addPillButton(text) {
    // Create new button element
    const button = document.createElement("button");
    const safeText = this.sanitizeText(text);

    // Store the text as a data attribute
    button.setAttribute("data-filter-text", text);
    // Add the pill text and icon
    button.innerHTML = `
      <span>${safeText}</span>
      <img src="https://res.cloudinary.com/dannieltung/image/upload/v1708464787/Icon_close_mhanrx.svg" alt="cross-icon">
    `;

    // Append the new button to the buttons container
    this.buttonsContainerTarget.appendChild(button);
  }

  _handlePillButtonClick(event) {
    // Check if the clicked element is a button or inside a button
    const button = event.target.closest("button");

    if (button && this.buttonsContainerTarget.contains(button)) {
      const filterText = button.getAttribute("data-filter-text");

      // call the method to remove the 'active' class span element with the same filterText
      this._removeActiveFromClass(filterText);

      // Remove the button from the DOM
      this.buttonsContainerTarget.removeChild(button);

      // Check if the container is now empty and add 'hide' class if necessary
      if (this.buttonsContainerTarget.children.length === 0) {
        this.addHideClassToSelectedFilterContainer();
      }

      // If 'Usado' is being deactivated, add the 'hide' class to the sneakerGradeFilter
      if (filterText === "Usado") {
        this.sneakerGradeFilterTarget.classList.add("hide");
      }
    }
  }

  _removeActiveFromClass(filterText = null) {
    const filterMenuWrappers = document.querySelectorAll(
      ".filter-menu-wrapper"
    );
    filterMenuWrappers.forEach((wrapper) => {
      wrapper.querySelectorAll("span").forEach((span) => {
        // Check if the span's text content matches the filterText or if filterText is null
        if (
          filterText === null ||
          span.textContent.trim() === filterText.trim()
        ) {
          // Remove the 'active' class
          span.classList.remove("active");

          // Reset the innerHTML to just the text, removing the image
          span.innerHTML = span.textContent.trim();
        }
      });
    });
  }
}
