// app/javascript/controllers/toggle_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["icon"];

  connect() {
    // Correctly target each 'answer-wrapper' and add 'hide' class to make them initially hidden
    this.element.querySelectorAll(".answer-wrapper").forEach((el) => {
      el.classList.add("hide"); // Ensure all 'answer-wrapper' elements start as hidden
    });
  }

  toggle(event) {
    const infoContainer = event.currentTarget.closest(".question-container");
    const answerWrapper = infoContainer.querySelector(".answer-wrapper");
    answerWrapper.classList.toggle("hide");

    const isHidden = answerWrapper.classList.contains("hide");
    const arrowUp =
      "https://res.cloudinary.com/dannieltung/image/upload/v1709574358/Arrow_up_lv3fx2.svg";
    const arrowDown =
      "https://res.cloudinary.com/dannieltung/image/upload/v1709575512/Arrow_down_mshqvf.svg";

    const icon = infoContainer.querySelector("[data-target='toggle.icon']");
    icon.src = isHidden ? arrowDown : arrowUp;
  }
}
