// app/javascript/controllers/define_item_resume_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  removeItemFromSession(event) {
    event.preventDefault();

    const linkElement = event.target.closest("a[data-item-id]");

    if (!linkElement) {
      console.error("No item ID found in event target");
      return;
    }

    const itemId = linkElement.dataset.itemId;

    fetch(`/refresh_exchangeables_session/${itemId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to execute the request.");
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          // this.element.remove();
          const cardElement = document.getElementById(`sneaker-${itemId}`);
          if (cardElement) {
            cardElement.remove();
          }
        } else {
          console.error("Error message from server:", data.message);
        }
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }
}
