import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["alert", "notice"];

  connect() {
    this.startFadeOut();
  }

  startFadeOut() {
    if (this.hasAlertTarget) {
      this.fadeOut(this.alertTarget);
    }

    if (this.hasNoticeTarget) {
      this.fadeOut(this.noticeTarget);
    }
  }

  fadeOut(target) {
    setTimeout(() => {
      target.style.opacity = "0";
    }, 5000);
    setTimeout(() => {
      target.style.display = "none";
    }, 8000);
  }

  closeMessage(event) {
    event.preventDefault();

    if (this.hasAlertTarget) {
      this.alertTarget.style.display = "none";
    }

    if (this.hasNoticeTarget) {
      this.noticeTarget.style.display = "none";
    }
  }
}
