// app/javascript/controllers/select_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  selectGridItem(event) {
    // Prevents the event from affecting any parent elements
    event.stopPropagation();

    // Retrieve all grid-item elements within the same container
    const gridItems = this.element.querySelectorAll(".grid-item");

    // Remove 'selected' class from all grid-item elements
    gridItems.forEach((gridItem) => {
      gridItem.classList.remove("selected");
    });

    // Add the 'selected' class to the clicked element
    event.currentTarget.classList.add("selected");

    // Retrieve the size value from the first <span> within the clicked .grid-item
    const sizeValue = event.currentTarget.querySelector(".size").textContent;

    const advertisementId = event.currentTarget.dataset.advertisementId;

    const advertisementSizeId = event.currentTarget.dataset.advertisementSizeId;

    // Dispatch a custom event to signal that a grid item has been selected
    const enableEvent = new CustomEvent("enable-default-btn", {
      bubbles: true,
      detail: {
        size: sizeValue,
        advertisementId: advertisementId,
        advertisementSizeId: advertisementSizeId,
      },
    });
    this.element.dispatchEvent(enableEvent);
  }

  selectPillButton(event) {
    // Prevents the event from affecting any parent elements
    event.stopPropagation();

    // Retrieve all pill-btn elements within the same container
    const pills = this.element.querySelectorAll(".pill-btn");

    // Remove 'selected' class from all pill-btn elements
    pills.forEach((pill) => {
      pill.classList.remove("selected");
    });

    // Add the 'selected' class to the clicked element
    event.currentTarget.classList.add("selected");
  }
}
