// app/javascript/controllers/photo_upload_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "customFileButton",
    "hiddenFileInput",
    "uploadedPhotosContainer",
  ];

  connect() {
    this.imageData = [];
    this.observeMutations();
  }

  observeMutations() {
    const config = { childList: true, subtree: true };
    this.observer = new MutationObserver(() => this.updateVisibility());
    this.observer.observe(this.uploadedPhotosContainerTarget, config);
  }

  checkPhotoWrapperCount() {
    const photoWrappers =
      this.uploadedPhotosContainerTarget.querySelectorAll(".photo-wrapper");
    return photoWrappers.length;
  }

  // Dispatch the imageData status across the application
  dispatchImageData() {
    const event = new CustomEvent("imageDataUpdated", {
      detail: { imageData: this.imageData },
      bubbles: true,
    });
    this.element.dispatchEvent(event);
  }

  // Trigger file input
  selectMedia(event) {
    event.preventDefault();
    this.hiddenFileInputTarget.click();
  }

  // Handle file changes
  handleFiles() {
    const files = this.hiddenFileInputTarget.files;
    Array.from(files).forEach((file) => {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        alert("Apenas arquivos JPEG e PNG são permitidos.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => this.displayImage(event, file);
      reader.readAsDataURL(file);
    });
  }

  // Display image and handle imageData
  displayImage(event, file) {
    const id = this.imageData.length;
    const imgElement = document.createElement("img");
    imgElement.src = event.target.result;
    imgElement.className = "photo";

    const photoWrapper = document.createElement("div");
    photoWrapper.className = "photo-wrapper";
    photoWrapper.appendChild(imgElement);
    photoWrapper.appendChild(this.attachCloseIcon(id));

    this.uploadedPhotosContainerTarget.appendChild(photoWrapper);
    this.updateImageData(id, file, event.target.result);
    this.updateVisibility();
  }

  // Attach close icon and handle its event
  attachCloseIcon(id) {
    // Clone the SVG template
    const svgContainer = document.getElementById("svg-icons");
    const svgTemplate = svgContainer.innerHTML;
    const div = document.createElement("div"); // Temporary container for the SVG
    div.innerHTML = svgTemplate; // Insert the SVG HTML into the div
    const svg = div.querySelector("svg"); // Assume the SVG is the root element in the partial

    // Set dynamic attribute
    svg.addEventListener("click", () =>
      this.removeImage(id, svg.closest(".photo-wrapper"))
    );

    return svg;
  }

  // Update image data state
  updateImageData(id, file, dataURL) {
    this.imageData.push({
      id,
      name: file.name,
      type: file.type,
      size: file.size,
      dataURL,
    });

    this.dispatchImageData(); // Notify others of the imageData update
  }

  // Remove image and update state
  removeImage(id, element) {
    // Locate the element in 'imageData' by attribute id
    this.imageData = this.imageData.filter((image) => image.id !== id);
    if (element) {
      this.uploadedPhotosContainerTarget.removeChild(element);
    }
    this.updateVisibility();
    this.dispatchImageData(); // Notify others of the imageData update
  }

  // Update visibility of container based on imageData
  updateVisibility() {
    if (this.imageData.length > 0 || this.checkPhotoWrapperCount() > 0) {
      this.uploadedPhotosContainerTarget.classList.remove("hide");
    } else {
      this.uploadedPhotosContainerTarget.classList.add("hide");
    }
  }

  // Handle photo upload submission
  uploadPhotos(event) {
    event.preventDefault();
    if (this.imageData.length === 0) {
      return; // No images to upload
    }

    const uploadURL = "/advertisements/photo_upload";

    fetch(uploadURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('[name="csrf-token"]').content,
      },
      body: JSON.stringify({ photos: this.imageData }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Erro ao enviar as fotos. Por favor, tente novamente.");
      });
  }
}
