import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["star", "gradeLabel"];

  connect() {
    const selectedStar = this.starTargets.find((star) =>
      star.classList.contains("selected_grade")
    );

    this.updateStars(selectedStar);
  }

  select(event) {
    const clickedStarIndex = this.starTargets.indexOf(event.currentTarget);

    // Remove 'selected' class from all stars
    this.starTargets.forEach((star) => {
      star.classList.remove("selected_grade");
    });

    // Add 'selected' class to the clicked star
    this.starTargets[clickedStarIndex].classList.add("selected_grade");

    // Handles star filling
    this.starTargets.forEach((star, index) => {
      const svgPath = star.querySelector("svg path");
      if (index <= clickedStarIndex) {
        svgPath.style.fill = "#4728e3";
      } else {
        svgPath.style.fill = "#fcfdff";
      }

      // Clear previous grade labels
      this.gradeLabelTargets[index].textContent = "";
    });

    // Set the grade label of the clicked star
    this.gradeLabelTargets[clickedStarIndex].textContent =
      event.currentTarget.dataset.gradeLabel;
  }

  updateStars(selectedGradeEnum) {
    const selectedIndex = this.starTargets.indexOf(selectedGradeEnum);

    this.starTargets.forEach((star, index) => {
      const svgPath = star.querySelector("svg path");
      if (index <= selectedIndex) {
        svgPath.style.fill = "#4728e3";
        star.classList.add("selected_grade");
      } else {
        svgPath.style.fill = "#fcfdff";
        star.classList.remove("selected_grade");
      }

      // Clear previous grade labels
      this.gradeLabelTargets[index].textContent = "";
    });

    if (selectedIndex >= 0) {
      // Set the grade label of the selected star
      this.gradeLabelTargets[selectedIndex].textContent =
        this.starTargets[selectedIndex].dataset.gradeLabel;
    }
  }
}
