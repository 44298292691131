// app/javascript/controllers/card_row_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.element.addEventListener("click", this.handleClick.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("click", this.handleClick.bind(this));
  }

  handleClick(event) {
    if (!event.target.closest("a")) {
      window.location.href = this.linkTarget.getAttribute("href");
    }
  }
}
