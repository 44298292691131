import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.startScrolling();
  }

  startScrolling() {
    const contentWidth = this.contentTarget.offsetWidth;
    const containerWidth = this.element.offsetWidth;
    const scrollAmount = contentWidth + containerWidth;

    this.contentTarget.animate(
      [
        { transform: `translateX(${containerWidth}px)` },
        { transform: `translateX(-${contentWidth}px)` },
      ],
      {
        duration: 9200, // Adjust duration for speed
        iterations: Infinity,
      }
    );
  }
}
