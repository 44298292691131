/* javascript/controllers/mobile_hamburguer_menu_controller.js */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  closeDropdown() {
    this.menuTarget.style.display = "none";
    // Ensure we also update the aria-expanded attribute when closing the dropdown.
    this.element
      .querySelector(".navbar-dropdown-toggle")
      .setAttribute("aria-expanded", false);
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(event) {
    // Close the dropdown if a click outside is detected.
    if (!this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  /* CLOSE ICON em MOBILE dropdown */
  hideMobileDropdown() {
    this.hideMobileDropdownSearchResult();
    this.showStandardDom();
    this.removeMobileDropdown();
  }

  showMobileDropdown() {
    this.hideStandardDom();
    this.hideSearchResult();
  }

  toggleDropdown(event) {
    event.preventDefault();
    const isVisible = this.menuTarget.style.display === "block";
    this.menuTarget.style.display = isVisible ? "none" : "block";

    // Update aria-expanded attribute for accessibility
    this.element
      .querySelector(".navbar-dropdown-toggle")
      .setAttribute("aria-expanded", !isVisible);

    if (!isVisible) {
      // Ensures that clicks outside the dropdown will close it, without interfering with the initial click.
      setTimeout(
        () => document.addEventListener("click", this.handleClickOutside),
        0
      );
    } else {
      document.removeEventListener("click", this.handleClickOutside);
    }
  }

  toggleSubDropdown(event) {
    // Check if the event target or any of its parents is a link (anchor tag)
    const isLink = event.target.closest("a");

    // If the clicked element is a link and it's a sign-out link, return early and allow the default action
    if (isLink && isLink.dataset.turboMethod === "delete") {
      return;
    }

    event.preventDefault();

    // Find the closest parent item that is a navbar-dropdown-item
    let item = event.currentTarget.closest(".navbar-dropdown-item");

    // Toggle the 'active' class on the navbar-dropdown-item
    item.classList.toggle("active");

    // Find the sub-dropdown menu within this item
    let subDropdown = item.querySelector(".navbar-sub-dropdown-menu");

    // Toggle the display of the sub-dropdown menu
    if (subDropdown.style.display === "block") {
      subDropdown.style.display = "none";
    } else {
      subDropdown.style.display = "block";
    }

    // Optional: Close sub-dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (!item.contains(event.target)) {
        subDropdown.style.display = "none";
        item.classList.remove("active");
        document.removeEventListener("click", handleClickOutside);
      }
    };

    // Listen for clicks outside the sub-dropdown to close it
    document.addEventListener("click", handleClickOutside);
  }

  hideMobileDropdownSearchResult() {
    const mobileDropdownSearchResultElement = document.querySelector(
      "#mobile-dropdown-navbar-search-result"
    );
    if (mobileDropdownSearchResultElement) {
      mobileDropdownSearchResultElement.classList.add("hide");
    }
  }

  hideMobileDropdownSearchResult() {
    const mobileDropdownSearchResultElement = document.querySelector(
      "#mobile-dropdown-navbar-search-result"
    );
    if (mobileDropdownSearchResultElement) {
      mobileDropdownSearchResultElement.classList.add("hide");
    }
  }

  removeMobileDropdown() {
    const mobileDropdownElement = document.querySelector(
      "#mobile-hamburguer-menu"
    );
    if (mobileDropdownElement) {
      mobileDropdownElement.classList.add("hide");
    }
  }

  hideSearchResult() {
    const searchResultElement = document.querySelector("#navbar-search-result");
    if (searchResultElement) {
      searchResultElement.classList.add("hide");
    }
  }

  hideStandardDom() {
    const standardDomElement = document.querySelector("#new-standard-dom");
    if (standardDomElement) {
      standardDomElement.classList.add("hide");
    }
  }

  showStandardDom() {
    const standardDomElement = document.querySelector("#new-standard-dom");
    if (standardDomElement) {
      standardDomElement.classList.remove("hide");
    }
  }
}
