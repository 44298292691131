import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    previousValue: String,
  };

  connect() {
    this.previousValue = ""; // Initialize the previous value
  }

  formatPhoneNumber(event) {
    let input = event.target;
    let currentValue = input.value.replace(/\D/g, ""); // Remove all non-digits

    // If deleting and the previous value is formatted, handle differently
    if (currentValue.length < this.previousValue.length) {
      // Adjust logic if necessary for deletion handling
      // For now, just re-apply the raw numbers to avoid formatting issues
      input.value = currentValue;
    } else {
      // Apply formatting for non-deletion input
      let formatted = "";

      if (currentValue.length > 0) {
        let parts = ["("];
        parts.push(currentValue.substring(0, 2));
        parts.push(") ");

        if (currentValue.length <= 2) {
          formatted = parts.join("");
        } else if (currentValue.length <= 7) {
          parts.push(currentValue.substring(2, 7));
          formatted = parts.join("");
        } else {
          parts.push(currentValue.substring(2, 7));
          parts.push("-");
          parts.push(currentValue.substring(7, 11));
          formatted = parts.join("");
        }
      }

      input.value = formatted;
    }

    // Update the previous value
    this.previousValue = input.value.replace(/\D/g, "");
  }
}
