// app/javascript/controllers/define_item_condition_controller.js

import { Controller } from "@hotwired/stimulus";
import axios from "axios";

/**
 * This controller handles the item condition definition process, including toggling selections,
 * updating visibility of elements based on conditions, managing accessory selection, and updating the session.
 */
export default class extends Controller {
  static targets = [
    "sneakerConditionButton",
    "invoicePresenceButton",
    "option",
    "star",
    "toggleElement",
    "proceedButton",
    "uploadedPhotosContainer",
  ];

  /**
   * Called when the controller is connected to the DOM.
   * Sets up Axios headers and updates the visibility of elements based on the current state.
   */
  connect() {
    this.setAxiosHeaders();
    this.updateVisibility();
    this.observeMutations();
  }

  /**
   * Handler for imageData changes. Enables or disables the proceed button based on image data length and selected condition.
   * @param {Event} event - The event triggered when image data changes.
   */
  handleImageDataChange(event) {
    const imageDataLength = event.detail.imageData.length;
    const selectedCondition = this.sneakerConditionButtonTargets.find(
      (button) => button.classList.contains("selected")
    );
    if (selectedCondition.dataset.condition === "2" && imageDataLength > 0) {
      this.proceedButtonTarget.disabled = false;
    } else {
      this.proceedButtonTarget.disabled = true;
    }
  }

  /**
   * Updates the visibility of elements based on the selected sneaker condition.
   */
  updateVisibility() {
    const selectedCondition = this.sneakerConditionButtonTargets.find(
      (button) => button.classList.contains("selected")
    );
    const condition = selectedCondition.dataset.condition;

    if (condition === "2") {
      // 'Used' condition
      this.toggleElementTargets.forEach((el) => el.classList.remove("hide"));
      this.handleProceedButtonState();
      document.addEventListener(
        "imageDataUpdated",
        this.handleImageDataChange.bind(this)
      );
    } else {
      // 'New' condition
      this.toggleElementTargets.forEach((el) => el.classList.add("hide"));
      this.proceedButtonTarget.disabled = false;
    }
  }

  observeMutations() {
    const config = { childList: true, subtree: true };
    this.observer = new MutationObserver(() => this.handleProceedButtonState());
    this.observer.observe(this.uploadedPhotosContainerTarget, config);
  }

  handleProceedButtonState() {
    const photoWrapperCount = this.checkPhotoWrapperCount();
    this.proceedButtonTarget.disabled = photoWrapperCount === 0;
  }

  checkPhotoWrapperCount() {
    const photoWrappers =
      this.uploadedPhotosContainerTarget.querySelectorAll(".photo-wrapper");
    return photoWrappers.length;
  }

  /**
   * Sets the Axios headers with the CSRF token for secure requests.
   */
  setAxiosHeaders() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (csrfToken) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    }
  }

  /**
   * Toggles the selection of sneaker condition or invoice presence buttons.
   * @param {Event} event - The event triggered when a button is clicked.
   */
  toggleSelection(event) {
    const target = event.currentTarget;
    const buttonGroup = target.dataset.condition
      ? "sneakerConditionButton"
      : "invoicePresenceButton";
    const buttonTargets = this[`${buttonGroup}Targets`];

    buttonTargets.forEach((button) => {
      button.classList.remove("selected");
    });

    target.classList.add("selected");
    this.updateVisibility();
  }

  /**
   * Toggles the selection of box condition options.
   * @param {Event} event - The event triggered when an option is clicked.
   */
  toggleBoxCondition(event) {
    this.optionTargets.forEach((option) => {
      const activeIcon = option.querySelectorAll(".icon")[0];
      const inactiveIcon = option.querySelectorAll(".icon")[1];
      activeIcon.classList.add("hide");
      inactiveIcon.classList.remove("hide");
      option.classList.remove("active-option");
    });

    const clickedOption = event.currentTarget;
    const activeIcon = clickedOption.querySelectorAll(".icon")[0];
    const inactiveIcon = clickedOption.querySelectorAll(".icon")[1];
    activeIcon.classList.remove("hide");
    inactiveIcon.classList.add("hide");
    clickedOption.classList.add("active-option");
  }

  /**
   * Manages the selection of accessories.
   * @param {Event} event - The event triggered when an accessory is clicked.
   */
  manageAccessorySelection(event) {
    const clickedAccessory = event.currentTarget;
    const checkedIcon = clickedAccessory.querySelector(".checked");
    const uncheckedIcon = clickedAccessory.querySelector(".unchecked");

    if (checkedIcon.style.display === "none") {
      checkedIcon.style.display = "block";
      uncheckedIcon.style.display = "none";
    } else {
      checkedIcon.style.display = "none";
      uncheckedIcon.style.display = "block";
    }
  }

  /**
   * Updates the session with the current form state and navigates to the next step.
   * @param {Event} event - The event triggered when the proceed button is clicked.
   */
  updateSession(event) {
    event.preventDefault();

    // const formElement = event.currentTarget.form;
    // const href = formElement.action;
    // const href = "/update_advertisement_session";

    const condition = this.setSneakerCondition();
    const sneakerCondition = { sneaker_condition: condition };

    const boxConditionStatus = this.setSneakerBoxCondition();
    const boxCondition = { box_condition: boxConditionStatus };

    const invoicePresenceStatus = this.setInvoicePresence();
    const invoicePresence = { invoice_presence: invoicePresenceStatus };

    const selectedAccessories = this.setAccessories();
    const accessories = { accessories: selectedAccessories };

    const describedObservation = this.setObservation();
    const observation = { observation: describedObservation };

    const itemGradeValue = this.setItemGrade();
    const itemGrade = { item_grade: itemGradeValue };

    const payload = {
      advertisement: {
        ...sneakerCondition,
        ...boxCondition,
        ...invoicePresence,
        ...accessories,
        ...observation,
        ...itemGrade,
      },
    };

    // Make the Axios POST request
    axios
      .post("/update_advertisement_session", payload)
      .then((response) => {
        console.log("Session updated:", response);
        if (response.data.redirect) {
          window.location.href = response.data.redirect; // This line should trigger the redirect
        }
      })
      .catch((error) => {
        console.error("Error updating session:", error);
      });

    // axios
    //   .post(href, payload)
    //   .then((response) => {
    //     console.log("Session updated:", response);
    //     window.location.href = href;
    //   })
    //   .catch((error) => {
    //     console.error("Error updating session:", error);
    //   });
  }

  /**
   * Gets the selected item grade.
   * @returns {number} - The selected item grade value.
   */
  setItemGrade() {
    const reversedStars = [...this.starTargets].reverse();
    const itemGradeValue = reversedStars.find((star) =>
      star.classList.contains("selected_grade")
    );

    return itemGradeValue.dataset.gradeValue;
  }

  /**
   * Gets the selected sneaker condition.
   * @returns {string} - The selected sneaker condition value.
   */
  setSneakerCondition() {
    const selectedSneakerConditionButton =
      this.sneakerConditionButtonTargets.find((button) =>
        button.classList.contains("selected")
      );

    return selectedSneakerConditionButton.dataset.condition;
  }

  /**
   * Gets the selected invoice presence status.
   * @returns {number} - The selected invoice presence value.
   */
  setInvoicePresence() {
    const selectedInvoicePresenceButton =
      this.invoicePresenceButtonTargets.find((button) =>
        button.classList.contains("selected")
      );

    if (selectedInvoicePresenceButton) {
      const presence = selectedInvoicePresenceButton.dataset.invoicePresence;
      return parseInt(presence, 10);
    }
  }

  /**
   * Gets the observation text.
   * @returns {Array} - The observation text as an array.
   */
  setObservation() {
    const observation = [];
    const observationText = document
      .querySelector(".observation-input-control")
      .value.trim();

    if (observationText) {
      observation.push(observationText);
    }

    return observation;
  }

  /**
   * Gets the selected accessories.
   * @returns {Array} - The selected accessories.
   */
  setAccessories() {
    const accessories = [];
    const accessoryContainers = document.querySelectorAll(
      ".accessory-container"
    );

    accessoryContainers.forEach((accessoryContainer) => {
      const checkedIcon = accessoryContainer.querySelector(
        '.icon.checked[style*="display: block"]'
      );

      if (checkedIcon) {
        const accessorySpan = accessoryContainer.querySelector("span");
        if (accessorySpan) {
          accessories.push(accessorySpan.textContent.trim());
        }
      }
    });

    return accessories;
  }

  /**
   * Gets the selected box condition.
   * @returns {number} - The selected box condition value.
   */
  setSneakerBoxCondition() {
    const activeOptionContainer = document.querySelector(
      ".option-container.active-option"
    );

    if (activeOptionContainer) {
      const conditionSpan = activeOptionContainer.querySelector(
        'span[id^="condition_enum_"]'
      );

      if (conditionSpan) {
        const conditionValue = conditionSpan.id.replace("condition_enum_", "");
        const enumSelectedCondition = parseInt(conditionValue, 10);

        return enumSelectedCondition;
      }
    }
  }
}
