import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  navigateToUrl() {
    window.location.href = this.urlValue;
  }

  stopClickPropagation(event) {
    event.stopPropagation();
  }
}
