import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  connect() {
    this.setAxiosHeaders();
  }

  setAxiosHeaders() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (csrfToken) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    }
  }

  switchToggle(event) {
    const detailsElement = event.currentTarget; // This gets the specific .details div that was clicked
    const onIcon = detailsElement.querySelector(".icon.on");
    const offIcon = detailsElement.querySelector(".icon.off");

    // Toggle visibility based on the current visibility of the 'on' icon
    if (onIcon.style.display === "none") {
      onIcon.style.display = "block";
      offIcon.style.display = "none";
    } else {
      onIcon.style.display = "none";
      offIcon.style.display = "block";
    }
  }

  updateAddonsSession(event) {
    event.preventDefault();

    const href = event.currentTarget.href;

    const selectedAddonsID = [];

    const detailsElements = this.element.querySelectorAll(".details");
    detailsElements.forEach((detail) => {
      const onIcon = detail.querySelector(".icon.on");
      if (onIcon.style.display === "block") {
        const addonId = detail.querySelector("span").id; // Assuming the ID is stored as the span's ID
        selectedAddonsID.push(addonId);
      }
    });

    axios
      .post("/update_addons_session", { addon_ids: selectedAddonsID })
      .then((response) => {
        console.log("Session updated:", response);
        // Only navigate after the AJAX call is successful
        window.location.href = href;
      })
      .catch((error) => {
        console.error("Error updating session:", error);
      });
  }
}
