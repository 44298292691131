import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  connect() {
    console.log("Sale history controller connected");
  }

  toggleSelection(event) {
    this.buttonTargets.forEach((button) => {
      button.classList.remove("selected"); // Remove 'selected' from all buttons
    });
    event.currentTarget.classList.add("selected"); // Add 'selected' to the clicked button
  }
}
