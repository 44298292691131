import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["main", "thumbnail", "title"];

  swap(event) {
    // Prevent default link behavior
    event.preventDefault();

    // Get the clicked thumbnail's src and alt attributes
    const clickedImgSrc = event.currentTarget.querySelector("img").src;
    const clickedImgAlt = event.currentTarget.querySelector("img").alt;

    // Get the main image's src and alt attributes
    const mainImg = this.mainTarget.querySelector("img");
    const mainImgSrc = mainImg.src;
    const mainImgAlt = mainImg.alt;

    // Swap the images
    mainImg.src = clickedImgSrc;
    mainImg.alt = clickedImgAlt;
    event.currentTarget.querySelector("img").src = mainImgSrc;
    event.currentTarget.querySelector("img").alt = mainImgAlt;
  }
}
