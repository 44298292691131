// Function to convert string to title case
export function titleizeName(name) {
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => {
      if (word === "de") return word;
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
// Function to convert string to uppercase
export function toUpperCase(str) {
  return str.toUpperCase();
}
