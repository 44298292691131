// app/javascript/controllers/package_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // transformar em function?
  parseCurrencyToFloat(currencyStr) {
    // Removing currency symbol, replacing commas with periods for decimal point, and removing periods used as thousands separators
    const sanitizedStr = currencyStr.replace(/[^\d,]+/g, "").replace(/,/g, ".");

    const result = parseFloat(sanitizedStr);

    return result;
  }

  // transformar em function?
  formatFloatToCurrency(amount) {
    // Convert the amount to a string with two decimal places
    let [integerPart, decimalPart] = amount.toFixed(2).split(".");

    // Add thousands separator
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Combine the parts back with a comma for the decimal separator
    const formattedCurrency = `R$ ${integerPart},${decimalPart}`;

    return formattedCurrency;
  }

  toggleSelectedPackage(event) {
    // Prevents the event from affecting any parent elements
    event.stopPropagation();

    const element = event.currentTarget;

    element.classList.toggle("selected");

    // Determine if the package is selected and call the appropriate method
    if (element.classList.contains("selected")) {
      this.createCartItem(element.dataset.packageId);
    } else {
      this.destroyCartItem(element.dataset.packageId);
    }
  }

  createCartItem(packageId) {
    fetch("/cart_items", {
      method: "POST",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        "Content-Type": "application/json",
        Accept: "application/json", // Expecting JSON response
      },
      body: JSON.stringify({
        cart_item: {
          package_id: packageId,
          // Include any other necessary parameters that are being sent to controller
        },
      }),
    })
      .then((response) => response.json()) // Expecting JSON response
      .then((data) => {
        // localizar o elemento que contém o valor total dos serviços
        const servicesExpenseSpan = document.getElementById("services-expense");
        // valor total atual dos serviços
        const currentServiceTotal = this.parseCurrencyToFloat(
          servicesExpenseSpan.textContent
        );
        // novo valor total dos serviços
        const newServiceTotal = currentServiceTotal + data.amount;
        // inserindo na DOM
        servicesExpenseSpan.textContent =
          this.formatFloatToCurrency(newServiceTotal);

        // localizar o elemento que contém o valor final
        const grandTotalExpenseSpan = document.getElementById(
          "grand-total-expense"
        );
        // valor atual do pedido
        const currentGrandTotal = this.parseCurrencyToFloat(
          grandTotalExpenseSpan.textContent
        );
        // novo valor total do pedido
        const newGrandTotal = currentGrandTotal + data.amount;
        // inserindo na DOM
        grandTotalExpenseSpan.textContent =
          this.formatFloatToCurrency(newGrandTotal);
      });
  }

  destroyCartItem(packageId) {
    fetch(`/package_cart_item/${packageId}`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          // If the server response was not OK, throw an error to skip to the .catch block
          throw new Error(`Server responded with status ${response.status}`);
        }
        return response.json(); // Parse the response as JSON if the response was OK
      })
      .then((data) => {
        // localizar o elemento que contém o valor total dos serviços
        const servicesExpenseSpan = document.getElementById("services-expense");
        // valor total atual dos serviços
        const currentServiceTotal = this.parseCurrencyToFloat(
          servicesExpenseSpan.textContent
        );
        // novo valor total dos serviços
        const newServiceTotal = currentServiceTotal - data.amount;
        // inserindo na DOM
        servicesExpenseSpan.textContent =
          this.formatFloatToCurrency(newServiceTotal);

        // localizar o elemento que contém o valor final
        const grandTotalExpenseSpan = document.getElementById(
          "grand-total-expense"
        );
        // valor atual do pedido
        const currentGrandTotal = this.parseCurrencyToFloat(
          grandTotalExpenseSpan.textContent
        );
        // novo valor total do pedido
        const newGrandTotal = currentGrandTotal - data.amount;
        // inserindo na DOM
        grandTotalExpenseSpan.textContent =
          this.formatFloatToCurrency(newGrandTotal);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
