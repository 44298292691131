// app/javascript/controllers/handle_amount_input_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "defaultInput",
    "advertisementAmount",
    "advertisementBid",
    "dialogueAmount",
  ];

  connect() {
    if (this.hasDefaultInputTarget) {
      this.formatAmountInput(this.defaultInputTarget, true);
    }

    if (this.hasAdvertisementAmountTarget) {
      this.formatAmountInput(this.advertisementAmountTarget, true);
    }

    if (this.hasAdvertisementBidTarget) {
      this.formatAmountInput(this.advertisementBidTarget, false);
    }

    if (this.hasDialogueAmountTarget) {
      this.formatAmountInput(this.dialogueAmountTarget, true);
    }
  }

  formatAmountInput(inputElement, applyMaxValueConstraint = true) {
    inputElement.addEventListener("input", (event) => {
      let currentValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters

      // Check for max value constraint
      if (applyMaxValueConstraint) {
        const maxValue = 9999999; // Corresponds to '99.999,99'
        if (parseInt(currentValue) > maxValue) {
          currentValue = maxValue.toString();
        }
      }

      // Reverse the string
      let reversed = Array.from(currentValue).reverse().join("");

      // Add the decimal point
      let reversedWithDecimal =
        reversed.length > 1
          ? [reversed.slice(0, 2), ",", reversed.slice(2)].join("")
          : reversed;

      // Reverse the string back
      let reversedBack = Array.from(reversedWithDecimal).reverse().join("");

      // Add the thousand separators
      let withSeparators = reversedBack.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // Set the new value to the input
      event.target.value = withSeparators;
    });
  }
}
