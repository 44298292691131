import { Controller } from "@hotwired/stimulus";
import { titleizeName } from "../utilities/stringUtilities";

export default class extends Controller {
  static targets = [
    "email",
    "password",
    "passwordConfirmation",
    "loginButton",
    "fullName",
    "currentPassword",
    "passwordLengthValidation",
    "passwordContainsLetter",
    "passwordContainsNumber",
    "passwordContainsSymbol",
  ];

  connect() {
    this.updateButtonState();
  }

  updatePasswordValidationIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordLengthValidationTarget.querySelector(".icon");

      if (password.length >= 6) {
        icon.classList.remove("fa-xmark");
        icon.classList.add("fa-check");
        icon.style.color = "#219653"; // Apply color to the icon only
      } else {
        icon.classList.remove("fa-check");
        icon.classList.add("fa-xmark");
        icon.style.color = ""; // Reset icon color
      }
    }
  }

  updateContainsLetterIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsLetterTarget.querySelector(".icon");
      const containsLetter = /[a-zA-Z]/.test(password);

      if (containsLetter) {
        icon.classList.remove("fa-xmark");
        icon.classList.add("fa-check");
        icon.style.color = "#219653"; // Apply color to the icon only
      } else {
        icon.classList.remove("fa-check");
        icon.classList.add("fa-xmark");
        icon.style.color = ""; // Reset icon color
      }
    }
  }

  updateContainsNumberIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsNumberTarget.querySelector(".icon");
      const containsNumber = /\d/.test(password);

      if (containsNumber) {
        icon.classList.remove("fa-xmark");
        icon.classList.add("fa-check");
        icon.style.color = "#219653"; // Apply color to the icon only
      } else {
        icon.classList.remove("fa-check");
        icon.classList.add("fa-xmark");
        icon.style.color = ""; // Reset icon color
      }
    }
  }

  updateContainsSymbolIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsSymbolTarget.querySelector(".icon");
      const containsSymbol = /[^a-zA-Z0-9]/.test(password);

      if (containsSymbol) {
        icon.classList.remove("fa-xmark");
        icon.classList.add("fa-check");
        icon.style.color = "#219653"; // Apply color to the icon only
      } else {
        icon.classList.remove("fa-check");
        icon.classList.add("fa-xmark");
        icon.style.color = ""; // Reset icon color
      }
    }
  }

  isValidEmail(email) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  isValidFullName(fullName) {
    let nameParts = fullName.trim().split(" ");
    return nameParts.length >= 2;
  }

  areInputsValid() {
    const email = this.hasEmailTarget ? this.emailTarget.value : null;
    const password = this.hasPasswordTarget ? this.passwordTarget.value : null;
    const containsLetter = /[a-zA-Z]/.test(password);
    const containsNumber = /\d/.test(password);
    const containsSymbol = /[^a-zA-Z0-9]/.test(password);
    const passwordComplexityPassed =
      containsLetter && containsNumber && containsSymbol;

    const passwordConfirmation = this.hasPasswordConfirmationTarget
      ? this.passwordConfirmationTarget.value
      : null;
    const fullName = this.hasFullNameTarget ? this.fullNameTarget.value : null;
    const currentPassword = this.hasCurrentPasswordTarget
      ? this.currentPasswordTarget.value
      : null;

    const emailValidationPassed = this.hasEmailTarget
      ? this.isValidEmail(email)
      : true;

    // user forgot password
    if (!this.hasPasswordTarget) {
      return emailValidationPassed;
    }

    const passwordLengthValidationPassed =
      password.length >= 6 && passwordComplexityPassed;
    // user new session
    if (!this.hasPasswordConfirmationTarget) {
      return passwordLengthValidationPassed && emailValidationPassed;
    }

    const fullNameValidationPassed = this.hasFullNameTarget
      ? this.isValidFullName(fullName)
      : true;
    const passwordConfirmationValidationPassed =
      password === passwordConfirmation;

    // user set new password (from email)
    if (!this.hasEmailTarget && !this.hasFullNameTarget) {
      return (
        passwordLengthValidationPassed && passwordConfirmationValidationPassed
      );
    }

    // user new registration
    if (!this.hasCurrentPasswordTarget) {
      return (
        emailValidationPassed &&
        fullNameValidationPassed &&
        passwordLengthValidationPassed &&
        passwordConfirmationValidationPassed
      );
    }

    const currentPasswordLengthValidationPassed = currentPassword.length >= 6;

    // current user set new password
    return (
      emailValidationPassed &&
      currentPasswordLengthValidationPassed &&
      passwordLengthValidationPassed &&
      passwordConfirmationValidationPassed
    );
    // if (this.hasCurrentPasswordTarget) {

    // }
  }

  checkInputs() {
    this.loginButtonTarget.disabled = !this.areInputsValid();
  }

  updateButtonState() {
    this.loginButtonTarget.disabled = true;

    if (this.hasEmailTarget) {
      this.emailTarget.addEventListener("input", () => {
        this.checkInputs();
      });
    }

    if (this.hasPasswordTarget) {
      this.passwordTarget.addEventListener("input", () => {
        this.updatePasswordValidationIcon();
        this.updateContainsLetterIcon();
        this.updateContainsNumberIcon();
        this.updateContainsSymbolIcon();
        this.checkInputs();
      });
    }

    if (this.hasPasswordConfirmationTarget) {
      this.passwordConfirmationTarget.addEventListener("input", () =>
        this.checkInputs()
      );
    }

    if (this.hasFullNameTarget) {
      this.fullNameTarget.addEventListener("input", () => {
        this.fullNameTarget.value = titleizeName(this.fullNameTarget.value);
        this.checkInputs();
      });
    }

    if (this.hasCurrentPasswordTarget) {
      this.currentPasswordTarget.addEventListener("input", () =>
        this.checkInputs()
      );
    }
  }
}
