import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["defaultInput"];

  connect() {
    this.defaultInputTargets.forEach((input) => {
      input.addEventListener("keydown", this.preventNonNumeric.bind(this));
      input.addEventListener("input", this.restrictInput.bind(this));
    });
  }

  disconnect() {
    this.defaultInputTargets.forEach((input) => {
      input.removeEventListener("keydown", this.preventNonNumeric.bind(this));
      input.removeEventListener("input", this.restrictInput.bind(this));
    });
  }

  preventNonNumeric(event) {
    // Allow: backspace, delete, tab, escape, and enter
    if (
      [46, 8, 9, 27, 13, 110].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl/cmd+A
      (event.keyCode === 65 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+C
      (event.keyCode === 67 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+X
      (event.keyCode === 88 &&
        (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  restrictInput(event) {
    let input = event.target;
    let value = input.value;

    // Cleanup for any non-digit characters that might have been pasted in
    value = value.replace(/\D/g, "");

    input.value = value;
  }
}
