import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["imageInput", "sizeInfo"];

  displaySize() {
    this.sizeInfoTarget.innerHTML = ""; // Clear previous size info
    if (this.imageInputTarget.files.length > 0) {
      Array.from(this.imageInputTarget.files).forEach((file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            const fileInfo = document.createElement("div");
            fileInfo.textContent = `Arquivo: ${file.name} - ${width}px de Largura e ${height}px de Altura`;
            this.sizeInfoTarget.appendChild(fileInfo);
          };
          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      });
    }
  }
}
