// app/javascript/controllers/dropdown_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "toggleButton"];

  connect() {
    this.toggleLabel = this.element.querySelector(".dropdown-toggle span");
  }

  changeDropdownLabel(event) {
    event.preventDefault();
    const itemText = event.currentTarget.textContent.trim();
    this.toggleLabel.textContent = itemText;
    this.menuTarget.style.display = "none"; // Optionally close the menu
    // Create and dispatch a custom event with detail
    const updateEvent = new CustomEvent("updateDocumentType", {
      detail: { text: itemText },
      bubbles: true,
    });
    // Dispatch the event from the current element or a specific target that the other controller listens to
    this.element.dispatchEvent(updateEvent);
  }

  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    // Find the closest wrapper and then the menu within it
    const menu = event.currentTarget
      .closest(".dropdown")
      .querySelector('[data-dropdown-target="menu"]');

    const isDisplayed = menu.style.display === "block";
    menu.style.display = isDisplayed ? "none" : "block";

    if (!isDisplayed) {
      setTimeout(() => {
        document.addEventListener("click", (event) =>
          this.handleOutsideClick(event, menu)
        );
      }, 0);
    } else {
      document.removeEventListener("click", (event) =>
        this.handleOutsideClick(event, menu)
      );
    }
  }

  closeDropdown(event) {
    if (this.toggleButtonTarget) {
      this.changeToggleButtonContent(event);
    }
    this.menuTarget.style.display = "none"; // Close the dropdown menu by setting its display style to 'none'
  }

  changeToggleButtonContent(event) {
    const buttonText = event.currentTarget.textContent.trim();
    this.toggleButtonTarget.querySelector('span').textContent = buttonText; // Ensure you're targeting the span inside the button
  }

  handleOutsideClick(event, menu) {
    if (!menu.contains(event.target)) {
      menu.style.display = "none";
      // Remove event listener to clean up
      document.removeEventListener("click", (event) =>
        this.handleOutsideClick(event, menu)
      );
    }
  }
}
