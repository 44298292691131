// app/javascript/controllers/ui/swipe_controller.js
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'leftDesktop',
    'left',
    'activeImage',
    'right',
    'rightDesktop',
    'imageText',
  ];

  moveToLeft() {
    // Store the HTML content of each section
    const leftDesktopContent = this.leftDesktopTarget.innerHTML;
    const leftContent = this.leftTarget.innerHTML;
    const activeContent = this.activeImageTarget.innerHTML;
    const rightContent = this.rightTarget.innerHTML;
    const rightDesktopContent = this.rightDesktopTarget.innerHTML;

    // Swap the contents according to the specified logic
    this.leftDesktopTarget.innerHTML = rightDesktopContent;
    this.leftTarget.innerHTML = leftDesktopContent;
    this.activeImageTarget.innerHTML = leftContent;
    this.rightTarget.innerHTML = activeContent;
    this.rightDesktopTarget.innerHTML = rightContent;
    this.updateImageTextVisibility();
  }

  moveToRight() {
    // Store the HTML content of each section
    const leftDesktopContent = this.leftDesktopTarget.innerHTML;
    const leftContent = this.leftTarget.innerHTML;
    const activeContent = this.activeImageTarget.innerHTML;
    const rightContent = this.rightTarget.innerHTML;
    const rightDesktopContent = this.rightDesktopTarget.innerHTML;

    // Swap the contents according to the specified logic
    this.leftDesktopTarget.innerHTML = leftContent;
    this.leftTarget.innerHTML = activeContent;
    this.activeImageTarget.innerHTML = rightContent;
    this.rightTarget.innerHTML = rightDesktopContent;
    this.rightDesktopTarget.innerHTML = leftDesktopContent;
    this.updateImageTextVisibility();
  }

  updateImageTextVisibility() {
    this.imageTextTargets.forEach((element) => {
      if (element.closest('.active-image')) {
        element.classList.remove('hide');
      } else {
        element.classList.add('hide');
      }
    });
  }
}
