// app/javascript/controllers/define_item_exchangeable_controller.js
import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["selectedExchangeables"];

  connect() {
    this.setAxiosHeaders();
    this.checkCardWrapperCount();

    document.addEventListener(
      "itemAppended",
      this.showSelectedExchangeablesContainer.bind(this)
    );

    document.addEventListener(
      "itemRemoved",
      this.checkCardWrapperCount.bind(this)
    );
  }

  disconnect() {
    document.removeEventListener(
      "itemAppended",
      this.showSelectedExchangeablesContainer.bind(this)
    );

    document.removeEventListener(
      "itemRemoved",
      this.checkCardWrapperCount.bind(this)
    );
  }

  setAxiosHeaders() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (csrfToken) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    }
  }

  showSelectedExchangeablesContainer() {
    this.selectedExchangeablesTarget.classList.remove("hide");
  }

  checkCardWrapperCount() {
    const cardWrappers = document.querySelectorAll(
      "#selected-exchangeables-row .card-wrapper"
    );

    if (cardWrappers.length === 0) {
      this.selectedExchangeablesTarget.classList.add("hide");
    } else {
      this.selectedExchangeablesTarget.classList.remove("hide");
    }
  }

  updateExchangeablesSession(event) {
    event.preventDefault();

    const href = event.currentTarget.href;

    const selectedElements = document.querySelectorAll("[id^='sneaker-']");

    const itemIdsArray = Array.from(selectedElements).map(
      (element) => element.id.split("-")[1]
    );

    axios
      .post("/update_exchangeables_session", {
        exchangeable_items: itemIdsArray,
      })
      .then((response) => {
        console.log("Session updated:", response);
        // Only navigate after the AJAX call is successful
        window.location.href = href;
      })
      .catch((error) => {
        console.error("Error updating session:", error);
      });
  }
}
