// app/javascript/adjust_padding.js
document.addEventListener("turbo:load", () => {
  const fixedContainer = document.querySelector(".fixed-container");
  const scrollableContent = document.querySelector(".scrollable-content");

  if (fixedContainer && scrollableContent) {
    // Function to adjust padding-top of scrollableContent
    const adjustPaddingTop = () => {
      const height = fixedContainer.offsetHeight;
      scrollableContent.style.paddingTop = `${height}px`;
    };

    // Initial adjustment
    adjustPaddingTop();

    // Use MutationObserver to listen for changes in the fixedContainer
    const observer = new MutationObserver(adjustPaddingTop);

    // Configuration of the observer:
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
      characterData: true,
    };

    // Start observing the target node for configured mutations
    observer.observe(fixedContainer, config);

    // Ensure padding is adjusted on window resize as well
    window.addEventListener("resize", adjustPaddingTop);

    // Disconnect observer on page unload to prevent memory leaks
    window.addEventListener("unload", () => observer.disconnect());
  }
});
