// app/javascript/controllers/filter_menu_dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "dropdownItem"];

  select(event) {
    // Remove 'active' class from all items in the current dropdown
    this.dropdownItemTargets.forEach((item) => {
      if (
        item.closest(".filter-menu-dropdown") ===
        event.currentTarget.closest(".filter-menu-dropdown")
      ) {
        item.classList.remove("active");
      }
    });

    // Add 'active' class to the clicked item
    event.currentTarget.classList.add("active");

    // Existing logic to update the toggle text and re-append the arrow image
    const itemText = event.currentTarget.textContent;
    const dropdown = event.currentTarget.closest(".filter-menu-dropdown");
    const toggle = dropdown.querySelector(".filter-menu-dropdown-toggle");

    toggle.textContent = itemText;
    toggle.appendChild(this.arrowImage());

    // Close the dropdown menu
    this.closeDropdown(event);
  }

  arrowImage() {
    const img = document.createElement("img");
    img.src =
      "https://res.cloudinary.com/dannieltung/image/upload/v1708597237/Arrow_down_fqbj9m.svg";
    img.alt = "Arrow down";
    return img;
  }

  closeDropdown(event) {
    const dropdownToggle = event.currentTarget
      .closest(".filter-menu-dropdown")
      .querySelector('[data-bs-toggle="dropdown"]');
    const dropdownMenu = event.currentTarget
      .closest(".filter-menu-dropdown")
      .querySelector(".filter-menu-dropdown-menu");

    if (dropdownToggle && dropdownMenu) {
      // Set 'aria-expanded' to false
      dropdownToggle.setAttribute("aria-expanded", "false");
      // Hide the dropdown menu. Adjust this logic if you're using a specific CSS class to show/hide the menu.
      dropdownMenu.style.display = "none";
    }
  }
}
