import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.hideDesktopOrderResume();
  }

  hideDesktopOrderResume() {
    const desktopOrderResume = document.querySelector(".desktop-order-resume");
    if (desktopOrderResume) {
      desktopOrderResume.classList.add("hide");
    }
  }

  showDesktopOrderResume() {
    const desktopOrderResume = document.querySelector(".desktop-order-resume");
    if (desktopOrderResume) {
      desktopOrderResume.classList.remove("hide");
    }
  }

  hideOrderResume() {
    const orderResume = document.querySelector(".order-resume");
    if (orderResume) {
      orderResume.classList.add("hide");
    }

    this.showDesktopOrderResume();

    // Query for the .body-container
    // const bodyContainer = document.querySelector(".body-container");
    // if (bodyContainer) {
    //   // Change display to 'block' and remove the gap
    //   bodyContainer.style.display = "block";
    //   bodyContainer.style.gap = "0px"; // Or simply '' to remove the property
    // }
  }

  showOrderResume() {
    const orderResume = document.querySelector(".order-resume");
    if (orderResume && orderResume.classList.contains("hide")) {
      orderResume.classList.remove("hide");
    }

    this.hideDesktopOrderResume();
    
    // Query for the .body-container within
    // const bodyContainer = document.querySelector(".body-container");
    // if (bodyContainer) {
    //   // Change display to 'felx' and add a gap
    //   bodyContainer.style.display = "flex";
    //   bodyContainer.style.gap = "129px"; // Or simply '' to remove the property
    // }
  }
}
