import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["amountInput", "proceedButton"];

  connect() {
    if (this.hasAmountInputTarget) {
      this.formatAmountInput(this.amountInputTarget, false);
      // Check initial state of the input
      this.updateProceedButtonState();
      this.amountInputTarget.addEventListener(
        "input",
        this.updateProceedButtonState.bind(this)
      );
    }
    this.setAxiosHeaders();
  }

  setAxiosHeaders() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (csrfToken) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
    }
  }

  formatAmountInput(inputElement, applyMaxValueConstraint = true) {
    inputElement.addEventListener("input", (event) => {
      let currentValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters

      // Check for max value constraint
      if (applyMaxValueConstraint) {
        const maxValue = 9999999; // Corresponds to '99.999,99'
        if (parseInt(currentValue) > maxValue) {
          currentValue = maxValue.toString();
        }
      }

      // Reverse the string
      let reversed = Array.from(currentValue).reverse().join("");

      // Add the decimal point
      let reversedWithDecimal =
        reversed.length > 1
          ? [reversed.slice(0, 2), ",", reversed.slice(2)].join("")
          : reversed;

      // Reverse the string back
      let reversedBack = Array.from(reversedWithDecimal).reverse().join("");

      // Add the thousand separators
      let withSeparators = reversedBack.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      // Set the new value to the input
      event.target.value = withSeparators;
    });
  }

  updateSession(event) {
    event.preventDefault();

    // Get the raw value from the input field
    let rawValue = this.amountInputTarget.value;

    // const formElement = event.currentTarget.form;
    // const href = formElement.action;
    
    const href = this.proceedButtonTarget.dataset.url; // Get URL from data attribute

    // Prepare the data to be sent
    const advertisementAmount = { amount: rawValue };

    // Make a POST request to update the session
    axios
      .post("/update_advertisement_session", advertisementAmount)
      .then((response) => {
        console.log("Session updated:", response);
        // Only navigate after the AJAX call is successful
        window.location.href = href;
      })
      .catch((error) => {
        console.error("Error updating session:", error);
      });
  }

  updateProceedButtonState() {
    const inputAmountFilled = this.amountInputTarget.value.trim() !== "";
    if (inputAmountFilled === false) {
      proceedButton.classList.add("disabled");
      proceedButton.setAttribute("href", "javascript:void(0);"); // Disables the link
    } else {
      proceedButton.classList.remove("disabled");
      proceedButton.setAttribute("href", proceedButton.dataset.url); // Use the actual path from data attribute
    }
    // this.proceedButtonTarget.disabled = !inputAmountFilled;
  }
}
