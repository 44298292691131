// app/javascript/controllers/purchase_offer_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("enable-default-btn", (event) =>
      this.handleEnableDefaultBtn(event)
    );
  }

  submitTransactionForm(event) {
    if (
      event.currentTarget.classList.contains("disabled") ||
      event.currentTarget.hasAttribute("disabled")
    ) {
      event.preventDefault(); // Prevent the default link action
      event.stopPropagation();
      return; // Exit the function, effectively "disabling" the action
    }
    event.preventDefault(); // Prevent the default link action
    const form = document.getElementById("new-cart-item-form");
    form.requestSubmit();
  }

  handleEnableDefaultBtn(event) {
    this.enableDefaultButton();
    this.loadAdvertisementId(event);
    this.loadAdvertisementSizeId(event);
  }

  enableDefaultButton() {
    // Target the specific '.default-btn' you want to enable
    const defaultButtons = document.querySelectorAll(".default-btn.disabled");
    defaultButtons.forEach((button) => {
      // Remove the disabled attribute to "enable" the link
      button.removeAttribute("disabled");
      // Remove the disabled class to "enable" the link
      button.classList.remove("disabled");
    });
  }

  loadAdvertisementSizeId(event) {
    // Extract the advertisementSizeId value from the event detail
    const advertisementSizeId = event.detail.advertisementSizeId;

    const form = document.getElementById("new-cart-item-form");

    const advertisementSizeIdInput = form.querySelector(
      "[name='cart_item[advertisement_size_id]']"
    );

    if (advertisementSizeIdInput) {
      advertisementSizeIdInput.value = advertisementSizeId;
    }
  }

  loadAdvertisementId(event) {
    // Extract the advertisementSizeId value from the event detail
    const advertisementId = event.detail.advertisementId;

    const form = document.getElementById("new-cart-item-form");

    const advertisementIdInput = form.querySelector(
      "[name='cart_item[advertisement_id]']"
    );

    if (advertisementIdInput) {
      advertisementIdInput.value = advertisementId;
    }
  }
}

// script para passar o size as params pelo link_to
// addSizeAsParams(event) {
//   // Extract the size value from the event detail
//   const size = event.detail.size;

//   // Find the link to be updated
//   const purchaseNewLink = document.querySelector(
//     '[data-role="purchase-new-link"]'
//   );
//   if (purchaseNewLink) {
//     // Create a URL object using the current href of the purchase link
//     const currentUrl = new URL(
//       purchaseNewLink.getAttribute("href"),
//       window.location.origin
//     );

//     // Remove any existing 'size' query parameter
//     currentUrl.searchParams.delete("size");

//     // Add the new 'size' query parameter with the selected size
//     currentUrl.searchParams.append("size", size);

//     // Update the link's href attribute with the modified URL
//     purchaseNewLink.setAttribute("href", currentUrl.toString());
//   }
// }
