import { application } from "./application";

/* analizar */
import FilterMenuDropdownController from "./filter_menu_dropdown_controller";
application.register("filter-menu-dropdown", FilterMenuDropdownController);

import FilterMenuController from "./filter_menu_controller";
application.register("filter-menu", FilterMenuController);
/* fim de analizar */

import CardController from "./card_controller";
application.register("card", CardController);

import CartCheckoutController from "./cart_checkout_controller";
application.register("cart-checkout", CartCheckoutController);

import CarouselSwipeController from "./carousel_swipe_controller";
application.register("carousel-swipe", CarouselSwipeController);

import ClickableRowController from "./clickable_row_controller";
application.register("clickable-row", ClickableRowController);

import DefineItemConditionController from "./define_item_condition_controller";
application.register("define-item-condition", DefineItemConditionController);

import DefineItemSizeController from "./define_item_size_controller";
application.register("define-item-size", DefineItemSizeController);

import DefineItemAmountController from "./define_item_amount_controller";
application.register("define-item-amount", DefineItemAmountController);

import DefineItemAddonsController from "./define_item_addons_controller";
application.register("define-item-addons", DefineItemAddonsController);

import DefineItemResumeController from "./define_item_resume_controller";
application.register("define-item-resume", DefineItemResumeController);

import DefineItemExchangeableController from "./define_item_exchangeable_controller";
application.register(
  "define-item-exchangeable",
  DefineItemExchangeableController
);

import DeviseNewRegistrationFormController from "./devise_new_registration_form_controller";
application.register(
  "devise-new-registration-form",
  DeviseNewRegistrationFormController
);

import DeviseNewSessionFormController from "./devise_new_session_form_controller";
application.register("devise-new-session-form", DeviseNewSessionFormController);

import DeviseUpdateRegistrationFormController from "./devise_update_registration_form_controller";
application.register(
  "devise-update-registration-form",
  DeviseUpdateRegistrationFormController
);

import DropdownController from "./dropdown_controller";
application.register("dropdown", DropdownController);

import DynamicResizeController from "./dynamic_resize_controller";
application.register("dynamic-resize", DynamicResizeController);

import FlashMessageController from "./flash_message_controller";
application.register("flash-message", FlashMessageController);

import HandleAmountInputController from "./handle_amount_input_controller";
application.register("handle-amount-input", HandleAmountInputController);

import HandleNumericInputController from "./handle_numeric_input_controller";
application.register("handle-numeric-input", HandleNumericInputController);

import HandlePhoneNumberInputController from "./handle_phone_number_input_controller";
application.register(
  "handle-phone-number-input",
  HandlePhoneNumberInputController
);

import ImageswapController from "./imageswap_controller";
application.register("imageswap", ImageswapController);

import MarqueeController from "./marquee_controller";
application.register("marquee", MarqueeController);

import MobileHamburguerMenuController from "./mobile_hamburguer_menu_controller";
application.register("mobile-hamburguer-menu", MobileHamburguerMenuController);

import ModalController from "./modal_controller";
application.register("modal", ModalController);

import PackageController from "./package_controller";
application.register("package", PackageController);

import PhotoUploadController from "./photo_upload_controller";
application.register("photo-upload", PhotoUploadController);

import PurchaseOfferController from "./purchase_offer_controller";
application.register("purchase-offer", PurchaseOfferController);

import RedefinePasswordFormController from "./redefine_password_form_controller";
application.register("redefine-password-form", RedefinePasswordFormController);

import SalesHistoryController from "./sales_history_controller";
application.register("sales-history", SalesHistoryController);

import ScrollController from "./scroll_controller";
application.register("scroll", ScrollController);

/* mantive Search e MobileDropdownSearch próximos propositalmente */
import SearchController from "./search_controller";
application.register("search", SearchController);
import MobileDropdownSearchController from "./mobile_dropdown_search_controller";
application.register("mobile-dropdown-search", MobileDropdownSearchController);

import SelectController from "./select_controller";
application.register("select", SelectController);

import StarRatingController from "./star_rating_controller";
application.register("star-rating", StarRatingController);

import StateAndCityDropdownController from "./state_and_city_dropdown_controller";
application.register("state-and-city-dropdown", StateAndCityDropdownController);

import ToggleController from "./toggle_controller";
application.register("toggle", ToggleController);

import UploadSneakerImageController from "./upload_sneaker_image_controller";
application.register("upload-sneaker-image", UploadSneakerImageController);
