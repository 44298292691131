import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "email",
    "submit",
    "password",
    "passwordConfirmation",
    "passwordLengthValidation",
    "passwordContainsLetter",
    "passwordContainsNumber",
    "passwordContainsSymbol",
  ];

  connect() {
    if (this.hasPasswordTarget) {
      this.passwordTarget.addEventListener("input", () => {
        this.updatePasswordValidationIcon();
        this.updateContainsLetterIcon();
        this.updateContainsNumberIcon();
        this.updateContainsSymbolIcon();
        this.checkInputs();
      });
    }

    if (this.hasPasswordConfirmationTarget) {
      this.passwordConfirmationTarget.addEventListener("input", () =>
        this.checkInputs()
      );
    }
  }

  validateEmail() {
    const email = this.emailTarget.value;
    this.submitTarget.disabled = !this.isValidEmail(email);
  }

  isValidEmail(email) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  checkInputs() {
    if (this.hasPasswordTarget && this.hasPasswordConfirmationTarget) {
      this.submitTarget.disabled = !this.areInputsValid();
    }
  }

  areInputsValid() {
    if (this.hasPasswordTarget && this.hasPasswordConfirmationTarget) {
      const password = this.passwordTarget.value;
      const passwordConfirmation = this.passwordConfirmationTarget.value;
      const isLengthValid = password.length >= 6;
      const containsLetter = /[a-zA-Z]/.test(password);
      const containsNumber = /\d/.test(password);
      const containsSymbol = /[^a-zA-Z0-9]/.test(password);
      const isPasswordConfirmed = password === passwordConfirmation;

      return (
        isLengthValid &&
        containsLetter &&
        containsNumber &&
        containsSymbol &&
        isPasswordConfirmed
      );
    } else {
      return false;
    }
  }

  updateButtonState() {
    this.loginButtonTarget.disabled = true;

    if (this.hasPasswordTarget) {
      this.passwordTarget.addEventListener("input", () => {
        this.updatePasswordValidationIcon();
        this.updateContainsLetterIcon();
        this.updateContainsNumberIcon();
        this.updateContainsSymbolIcon();
        this.checkInputs();
      });
    }

    if (this.hasPasswordConfirmationTarget) {
      this.passwordConfirmationTarget.addEventListener("input", () =>
        this.checkInputs()
      );
    }
  }

  updateIconState(icon, condition) {
    if (condition) {
      icon.classList.remove("fa-xmark");
      icon.classList.add("fa-check");
      icon.style.color = "#219653"; // Apply color to the icon only
    } else {
      icon.classList.remove("fa-check");
      icon.classList.add("fa-xmark");
      icon.style.color = ""; // Reset icon color
    }
  }

  updatePasswordValidationIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordLengthValidationTarget.querySelector(".icon");
      this.updateIconState(icon, password.length >= 6);
    }
  }

  updateContainsLetterIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsLetterTarget.querySelector(".icon");
      const containsLetter = /[a-zA-Z]/.test(password);
      this.updateIconState(icon, containsLetter);
    }
  }

  updateContainsNumberIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsNumberTarget.querySelector(".icon");
      const containsNumber = /\d/.test(password);
      this.updateIconState(icon, containsNumber);
    }
  }

  updateContainsSymbolIcon() {
    if (this.hasPasswordTarget) {
      const password = this.passwordTarget.value;
      const icon = this.passwordContainsSymbolTarget.querySelector(".icon");
      const containsSymbol = /[^a-zA-Z0-9]/.test(password);
      this.updateIconState(icon, containsSymbol);
    }
  }
}
