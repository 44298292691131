import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cityDropdown", "cityToggle"];

  connect() {
    this.brazilianStatesAndCities = JSON.parse(
      this.data.get("brazilian-states-and-cities-value")
    );
  }

  selectState(event) {
    const stateSigla = event.currentTarget.dataset.stateSigla;
    this.updateCityDropdown(stateSigla);
    this.resetCityToggle();
  }

  updateCityDropdown(sigla) {
    const selectedState = this.brazilianStatesAndCities.estados.find(
      (state) => state.sigla === sigla
    );
    const cities = selectedState ? selectedState.cidades : [];

    // Clear existing cities
    this.cityDropdownTarget.innerHTML = "";

    // Populate the dropdown with new cities
    cities.forEach((city) => {
      const li = document.createElement("li");
      li.classList.add("default-dropdown-item");
      li.setAttribute("data-action", "click->filter-menu-dropdown#select");
      li.textContent = city;
      this.cityDropdownTarget.appendChild(li);
    });
  }

  resetCityToggle() {
    // Update the city dropdown toggle text and icon
    this.cityToggleTarget.innerHTML = `Cidade <img src="https://res.cloudinary.com/dannieltung/image/upload/v1708597237/Arrow_down_fqbj9m.svg" alt="Arrow down">`;
  }
}
