// app/javascript/controllers/modal_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  closeIfOutside(event) {
    if (event.target === this.modalTarget) {
      // Check if the click was on the overlay
      this.close();
    }
  }

  close() {
    const modalElement = document.querySelector("#modal");
    if (modalElement) {
      modalElement.classList.add("hide");
    }
  }
}
