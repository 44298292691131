import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sourceEmail", "password", "loginButton"];

  connect() {
    this.passwordTarget.addEventListener("input", () => {
      this.checkPasswordInput();
    });
  }

  checkPasswordInput() {
    this.updateLoginButtonState();
  }

  isValidEmail(email) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  }

  updateLoginButtonState() {
    const emailIsValid = this.isValidEmail(this.sourceEmailTarget.value);
    const passwordIsNotEmpty = this.passwordTarget.value !== "";

    this.loginButtonTarget.disabled = !(emailIsValid && passwordIsNotEmpty);
  }
}
