import { Controller } from "@hotwired/stimulus";
// import { viewport } from "@popperjs/core";

export default class extends Controller {
  static targets = [
    "headBannerContainer",
    "headBannerCall1",
    "headBannerCall2",
    "subBannerContainer",
  ];

  connect() {
    this.resize();
    window.addEventListener("resize", () => this.resize());
  }

  disconnect() {
    window.removeEventListener("resize", () => this.resize());
  }

  resize() {
    const viewportWidth = window.innerWidth;

    // head-banner-container call-1
    if (this.hasHeadBannerCall1Target) {
      const call1MarginTop = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        758,
        209,
        42
      );

      const call1Width = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        376,
        331
      );
      
      const call1Height = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        80.653,
        71
      );

      const call1SpanElement = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        31.807,
        24
      );

      this.headBannerCall1Target.style.marginTop = `${call1MarginTop}px`;
      this.headBannerCall1Target.style.width = `${call1Width}px`;
      this.headBannerCall1Target.querySelector(
        "span"
      ).style.fontSize = `${call1SpanElement}px`;
      this.headBannerCall1Target.style.height = `${call1Height}px`;
    }

    // head-banner-container call-2
    if (this.hasHeadBannerCall2Target) {
      const marginTop = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        9.35,
        10
      );

      const marginBottom = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        29,
        5
      );

      const fontSize = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        56,
        36
      );

      this.headBannerCall2Target.style.marginTop = `${marginTop}px`;
      this.headBannerCall2Target.style.marginBottom = `${marginBottom}px`;
      this.headBannerCall2Target.querySelector(
        "span"
      ).style.fontSize = `${fontSize}px`;
    }

    // sub-banner-container
    if (this.hasSubBannerContainerTarget) {
      const styleValues = this.calculateSubBannerContainerValues(viewportWidth);

      // Fetch the needed elements
      const callToActionElement =
        this.subBannerContainerTarget.querySelector(".call-to-action");
      const call1Element =
        this.subBannerContainerTarget.querySelector(".call-1");
      const call1SpanElement = call1Element.querySelector("span");
      const actionLinkSpanElement =
        callToActionElement.querySelector(".action-link span");

      const imageElement =
        this.subBannerContainerTarget.querySelector(".sub-banner");

      call1Element.style.marginTop = `${styleValues.call1MarginTop}px`;
      call1Element.style.marginBottom = `${styleValues.call1MarginBottom}px`;
      call1SpanElement.style.fontSize = `${styleValues.call1SpanSize}px`;
      callToActionElement.style.paddingLeft = `${styleValues.callToActionPaddingLeft}px`;
    }

    // head-banner-container
    if (this.hasHeadBannerContainerTarget) {
      const paddingLeft = this.calculateResponsiveValue(
        viewportWidth,
        1440,
        759,
        151,
        31
      );

      const callToActionElement =
        this.headBannerContainerTarget.querySelector(".call-to-action");

      callToActionElement.style.paddingLeft = `${paddingLeft}px`;
    }
  }

  calculateSubBannerContainerValues(viewportWidth) {
    return {
      call1MarginTop: this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        87,
        39
      ),
      call1MarginBottom: this.calculateResponsiveValue(
        viewportWidth,
        1440,
        360,
        50,
        46
      ),
      call1SpanSize: this.calculateResponsiveValue(
        viewportWidth,
        1440,
        360,
        74,
        60
      ),
      callToActionPaddingLeft: this.calculateResponsiveValue(
        viewportWidth,
        1440,
        375,
        179,
        28
      )
    };
  }

  calculateResponsiveValue(
    viewportWidth,
    maxWidth,
    minWidth,
    maxValue,
    minValue
  ) {
    if (viewportWidth >= maxWidth) {
      return maxValue;
    } else if (viewportWidth <= minWidth) {
      return minValue;
    } else {
      const slope = (maxValue - minValue) / (maxWidth - minWidth);
      return minValue + slope * (viewportWidth - minWidth);
    }
  }
}
