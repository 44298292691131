import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["day", "month", "year"];

  connect() {
    this.yearTarget.addEventListener(
      "blur",
      this.validateYearOnBlur.bind(this)
    );
    this.element.addEventListener(
      "updateDocumentType",
      this.updateDocumentTypeField.bind(this)
    );
  }

  disconnect() {
    // Clean up event listeners
    this.yearTarget.removeEventListener(
      "blur",
      this.validateYearOnBlur.bind(this)
    );
    this.element.removeEventListener(
      "updateDocumentType",
      this.updateDocumentTypeField.bind(this)
    );
  }

  dateFieldInput(event) {
    const input = event.target;
    input.value = input.value.replace(/\D/g, ""); // Clean non-digit characters

    // Apply length-based restrictions
    if (input.id === "day" || input.id === "month") {
      input.value = input.value.substring(0, 2); // Limit day and month to 2 digits
      this.immediateValidation(input);
    } else if (input.id === "year") {
      if (input.value.length <= 4) {
        this.deferValidation(input); // Defer validation for year until blur
      }
    }
  }

  immediateValidation(input) {
    // Immediate validation for day and month
    if (!this.validateInput(input, parseInt(input.value, 10))) {
      input.value = ""; // Clear the input field if not valid
      this.showError(input.id); // Show an error
    }
  }

  deferValidation(input) {
    clearTimeout(this.deferValidationTimeout);
    this.deferValidationTimeout = setTimeout(() => {
      if (!this.validateInput(input, parseInt(input.value, 10))) {
        input.value = ""; // Clear the input if not valid
        this.showError(input.id); // Show an error
      }
    }, 600); // Adjust delay as necessary
  }

  validateYearOnBlur(event) {
    const input = event.target;
    if (!this.validateInput(input, parseInt(input.value, 10))) {
      input.value = ""; // Clear the input field if not valid
      this.showError(input.id); // Show an error
    }

    this.updateBirthdayField();
  }

  validateInput(input, value) {
    let minValue = 1;
    let maxValue;

    switch (input.id) {
      case "day":
        maxValue = 31;
        break;
      case "month":
        maxValue = 12;
        break;
      case "year":
        minValue = 1924;
        maxValue = 2024;
        break;
      default:
        console.error("Unknown input ID");
        return false;
    }

    // Check if the value is within the valid range
    return value >= minValue && value <= maxValue;
  }

  showError(inputId) {
    // Placeholder for error display logic
    console.error(`${inputId} has an invalid value.`);
    // Implement user-friendly error display here, such as showing a message in the UI
  }

  updateBirthdayField() {
    // Ensure all fields are filled and valid before proceeding
    if (this.areAllFieldsFilledAndValid()) {
      const day = this.dayTarget.value.padStart(2, "0"); // Pad single digit days
      const month = this.monthTarget.value.padStart(2, "0"); // Pad single digit months
      const year = this.yearTarget.value; // Year should already be 4 digits

      // Construct the date string
      const formattedDate = `${day}-${month}-${year}`;

      // Find the hidden field in the DOM
      const birthdayField = document.getElementById("birthday-field");
      if (birthdayField) {
        // Update the hidden field's value
        birthdayField.value = formattedDate;
      }
    }
  }

  areAllFieldsFilledAndValid() {
    const dayValid = this.validateInput(
      this.dayTarget,
      parseInt(this.dayTarget.value, 10)
    );
    const monthValid = this.validateInput(
      this.monthTarget,
      parseInt(this.monthTarget.value, 10)
    );
    const yearValid = this.validateInput(
      this.yearTarget,
      parseInt(this.yearTarget.value, 10)
    );

    // Check if day, month, and year are within their valid ranges
    return dayValid && monthValid && yearValid;
  }

  updateDocumentTypeField(event) {
    const documentTypeField = document.getElementById("document-type-field");
    if (documentTypeField) {
      documentTypeField.value = event.detail.text;
    }
  }
}
