import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target"];

  scrollTo() {
    const element = this.targetTarget;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
  