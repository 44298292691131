// app/javascript/controllers/define_item_size_controller.js
import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static targets = ["label", "size", "proceedButton"];

  connect() {
    this.setAxiosHeaders();
    this.updateProceedButtonState();
  }

  updateProceedButtonState() {
    const selectedSizesCount = this.countSelectedSizes();
    const proceedButton = this.proceedButtonTarget;

    if (selectedSizesCount === 0) {
      proceedButton.classList.add("disabled");
    } else {
      proceedButton.classList.remove("disabled");
    }
  }

  handleNavigation(event) {
    // Prevent the default link behavior
    event.preventDefault();

    // Only navigate if the button is not disabled
    if (!this.proceedButtonTarget.classList.contains("disabled")) {
      window.location.href = this.proceedButtonTarget.getAttribute("href");
    }
  }

  countSelectedSizes() {
    const selectedSizes = this.sizeTargets.filter((size) =>
      size.classList.contains("selected")
    );
    return selectedSizes.length;
  }

  setAxiosHeaders() {
    const csrfToken = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    if (csrfToken) {
      axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
      // console.log("CSRF Token set for Axios");
    } else {
      console.error("No CSRF token found");
    }
  }

  removeAllSelectedClasses() {
    this.sizeTargets.forEach((element) => {
      element.classList.remove("selected");
    });
  }

  async toggleSelection(event) {
    this.labelTargets.forEach((label) => {
      label.classList.remove("selected");
    });

    event.currentTarget.classList.add("selected");

    // Ensure there is a valid text content before proceeding
    const sizeSystemText = event.currentTarget.textContent
      .trim()
      .toLowerCase()
      .replace(/ /g, "_");

    if (!sizeSystemText) {
      console.log("No size system text found");
      return; // Exit the function if no valid text content
    }

    try {
      // Trigger Turbo Stream to update grid of sizes
      await this.updateSizesGrid(sizeSystemText);

      // Make a DELETE AJAX call to clear the session data
      await this.deleteAdvertisementSizesSession();

      // After all actions, remove all selected classes from size targets
      this.removeAllSelectedClasses();
      // After all actions, update proceedButton target state
      this.updateProceedButtonState();
    } catch (error) {
      console.error("Error in toggleSelection: ", error);
    }
  }

  async deleteAdvertisementSizesSession() {
    try {
      const response = await axios.delete("/delete_sizes_session");
      console.log("Session cleared", response);
    } catch (error) {
      console.log("Error clearing session:", error);
    }
  }

  manageSelection(event) {
    const sizeElement = event.currentTarget;

    sizeElement.classList.toggle("selected");

    const action = sizeElement.classList.contains("selected")
      ? "add"
      : "remove";

    const selectedLabel = this.labelTargets.find((label) =>
      label.classList.contains("selected")
    );

    let selectedSystem;

    if (selectedLabel) {
      selectedSystem = selectedLabel.textContent.trim();
    }

    this.updateSessionSizes(
      selectedSystem,
      sizeElement.textContent.trim(),
      action
    );

    this.updateProceedButtonState();
  }

  updateSessionSizes(sizeSystem, size, action) {
    axios
      .post(
        "/update_sizes_session",
        {
          size_system: sizeSystem,
          size: size,
          action_type: action, // renamed from 'action' to 'action_type' to avoid potential reserved word conflicts
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => console.log("Session updated:", response))
      .catch((error) => console.log("Error updating session:", error));
  }

  async updateSizesGrid(sizeSystem) {
    const url = "/update_sizes_grid";
    const data = { size_system: sizeSystem };
    const token = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");

    try {
      // Await the fetch call to resolve, and then process the response
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "application/json",
          Accept: "text/vnd.turbo-stream.html",
        },
        body: JSON.stringify(data),
      });

      // Check if the response is OK (status in the range 200-299)
      if (response.ok) {
        const html = await response.text(); // Retrieve the Turbo Stream response text
        // Create a DOM parser to parse the Turbo Stream response
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        // Ensure Turbo Stream response contains turbo-stream elements
        const turboStreamElements = doc.querySelectorAll("turbo-stream");
        if (turboStreamElements.length > 0) {
          turboStreamElements.forEach((element) => {
            document.body.appendChild(element);
          });
          console.log("Turbo Stream update applied.");
        } else {
          console.error("No Turbo Stream elements found in response.");
        }
      } else {
        throw new Error(`Failed to fetch with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  }
}

// updateProceedButtonState() {
//   const selectedSizesCount = this.countSelectedSizes();
//   const proceedButton = document.getElementById("proceedButton");
//   // this.proceedButtonTarget.disabled = selectedSizesCount === 0;
//   if (selectedSizesCount === 0) {
//     proceedButton.classList.add("disabled");
//     proceedButton.setAttribute("href", "javascript:void(0);"); // Disables the link
//   } else {
//     proceedButton.classList.remove("disabled");
//     proceedButton.setAttribute("href", proceedButton.dataset.url); // Use the actual path from data attribute
//   }
// }
